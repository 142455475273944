<template>
  <ContainerCard :locationMsg="locationMsg">
    <div class="GroupDetails">
      <p class="title">{{ groupDetail.name }}</p>
      <div class="groupContent">
        <div class="groupSelect">
          <el-menu
            :default-active="selectIndex"
            active-text-color="#2885FF"
            @select="selectGroup"
            >
            <el-menu-item index=0>
              <span slot="title">名称</span>
            </el-menu-item>
            <el-menu-item index=1>
              <span slot="title">简介</span>
            </el-menu-item>
            <el-menu-item index=2>
              <span slot="title">章程</span>
            </el-menu-item>
            <el-menu-item index=3>
              <span slot="title">成员名单</span>
            </el-menu-item>
            <el-menu-item index=4>
              <span slot="title">理事会名单</span>
            </el-menu-item>
          </el-menu>
        </div>

        <div class="groupItem">
          <div class="info-title">
            <img src="../../assets/images/信息资讯.png" alt="" />
            <span class="label">职教集团{{selectList[selectIndex]}}</span>
          </div>
          <div class="groupDetail" v-show="selectIndex == 0">{{groupDetail.name}}</div>
          <div class="groupDetail" v-show="selectIndex == 1" v-html="groupDetail.introduction"></div>
          <div class="groupDetail" v-show="selectIndex == 2" v-html="groupDetail.constitution"></div>
          <!-- 优化项   成员名单区分学校和企业  学校：schoolNameList  企业：memberNameList -->
          <div class="membership" v-show="selectIndex == 3">
            <div class="groupDetail" v-for="(item,index) in groupDetail.memberNameList" :key="index">
              <div class="memberList">{{item}}</div>
            </div>
            <!-- 定位到右侧实现 -->
            <div class="membership-r">
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">职教集团学校名单</span>
              <div class="groupDetail" v-for="(item,index) in groupDetail.schoolNameList" :key="index">
                <div class="memberList">{{item}}</div>
              </div>
            </div>
          </div>
          <div class="groupDetail" v-show="selectIndex == 4" v-for="(item) in groupDetail.councilNameList" :key="item">
            <div class="memberList">{{item}}</div>
          </div>
        </div>
      </div>
    </div>

  </ContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import {
  getAllianceById,
} from "../../api/group/group.js";
import { getPlainText } from "../../common/utils/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      locationMsg: [
        { name: "职教集团", router: "group" },
        { name: "集团详情", router: "group/groupDetails" },
      ],
      groupDetail: {
        name: "",
      },
      selectIndex:"0",
      selectList:["名称","简介","章程","企业名单","理事会名单"]
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      let res = await getAllianceById({ id: id });
      let tmp = res.data;
      tmp.introduction = getPlainText(getPlainText(res.data.introduction));
			tmp.constitution = getPlainText(getPlainText(res.data.constitution));
      this.groupDetail = tmp;
    },
    selectGroup(key) {
      this.selectIndex = key;
    }
  },
  components: { Location, ContainerCard },
};
</script>

<style scoped lang="scss">
@mixin textStyle {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #101010;
}
::v-deep .locationList {
  position: absolute;
  left: -27px;
}
.GroupDetails {
  width: 1075px;
  // height: 880px;
  min-height: 550px;
  // padding-bottom: 30px;
}
.title{
  font-size: 24px;
  font-weight: bold;
  color: #101010;
  margin: 24px 0;
}
.groupContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 40px;
}
.groupSelect{
  width: 250px;
  margin-right: 30px;
}
.groupItem {
  position: relative;
  width: 696px;
  box-sizing: border-box;
  // border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 0px 30px 0px 0px;
  .info-title {
    margin-bottom: 12px;
    img {
      width: 8px;
      height: 10px;
      margin-right: 12px;
    }
    .label {
      font-size: 16px;
      font-family: 思源黑体 CN Medium;
      color: #101010;
      line-height: 24px;
    }
  }

  .groupDetail{
    font-size: 16px;
    color: #404040;
    line-height: 36px;
  }

  .membership {
    .membership-r {
      position: absolute;
      right: 0;
      top: 0;

      img {
        width: 8px;
        height: 10px;
        margin-right: 12px;
      }
      .groupDetail {
        transform: translateY(18px);
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>