import request from '../../common/utils/request'

// 翻页查找职教集团
export function getAlliances(data) {
  return request({
    url: "/itf/common/cjrh/alliance/getAlliances.json",
    method: "get",
    params: data
  });
}

// 根据Id查询职教集团详细信息
export function getAllianceById(data) {
  return request({
    url: "	/itf/common/cjrh/alliance/getAllianceById.json",
    method: "get",
    params: data
  });
}