<template>
  <div class="Location">
    <!-- 当前的位置：<span
      v-for="(item, index) in location"
      :key="index"
      @click="toPage(item.router)"
      >{{ item.name | addChild }}</span> -->
    <div class="locationList">
      <span
        v-for="(item, index) in location"
        :key="index"
        @click="toPage(item.router)"
      >
        <span
          :class="[
            index === location.length - 1
              ? 'location-font-active'
              : 'location-font',
          ]"
        >
          {{ item.name }}</span
        >
        <span class="location-font" v-if="index != location.length - 1">/</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["locationMsg"],
  data() {
    return {
      location: this.locationMsg,
    };
  },
  methods: {
    toPage(router) {
      if (router && `/${router}` != this.$route.path) {
        this.$router.push(`/${router}`);
      } else {
        // console.log("fail");
      }
    },
  },
  filters: {
    addChild: function (value, index) {
      return (value = value + " / ");
    },
  },
};
</script>
<style scoped>
.Location {
  width: 756px;
  height: 62px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: rgba(242, 242, 242, 1);
  /* padding: 25px 30px; */
  /* margin-bottom: 20px; */
  background: #fff;
  padding: 24px 30px 0px 0px;
  /* position: relative; */
  /* margin: 0 auto; */
}
.locationList {
  height: 38px;
  line-height: 38px;
  margin-left: 30px;
}
.locationList > span {
  font-size: 12px;
  cursor: pointer;
  font-family: Regular;
  color: #999999;
}
.Location > span {
  cursor: pointer;
  color: #101010;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #101010;
}
.Location > span:hover {
  color: #02a7f0;
}

.location-font {
  font-size: 12px;
  color: #999999;
  font-family: Regular;
  margin-right: 12px;
}
.location-font-active {
  font-size: 12px;
  color: #404040;
  font-family: Regular;
  margin-right: 12px;
}
</style>